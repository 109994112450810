import FirebaseDatabase from '../services/FirebaseDatabase';
import { notifyHandledError } from '../services/ErrorMonitoring';
import { draftsPaths } from '../utils/constants/drafts';

async function listDrafts(coachId, type) {
  let res = null;
  try {
    res = await FirebaseDatabase.getValue(`${draftsPaths[type]}/${coachId}`);

    if (res) {
      res = Object.values(res);
    }
  } catch (error) {
    notifyHandledError(error, { message: 'Unable to get draft list' });
  }
  return res;
}

async function deleteDraft({ coachId, type, draftId }) {
  let res = null;
  try {
    if (!coachId || !type) return null;
    res = await FirebaseDatabase.removeValue(
      `${draftsPaths[type]}/${coachId}/${draftId}`
    );
    res = deleteDraft;
  } catch (error) {
    notifyHandledError(error, { message: 'Unable to delete draft' });
  }
  return res;
}

async function updateDraft({ coachId, type, data, draftId }) {
  let res = null;
  try {
    if (!coachId || !type || !draftId) return null;
    const draftData = { ...data, id: draftId };
    await FirebaseDatabase.updateValue(
      `${draftsPaths[type]}/${coachId}/${draftId}`,
      draftData
    );
    res = draftData;
  } catch (error) {
    notifyHandledError(error, { message: 'Unable to update draft' });
  }
  return res;
}

async function createDraft({ coachId, type, data }) {
  let res = null;
  try {
    if (!coachId || !type) return null;
    const draftId = await FirebaseDatabase.generateId(
      `${draftsPaths[type]}/${coachId}`
    );
    if (!draftId) return null;
    const draftData = { ...data, id: draftId };
    await FirebaseDatabase.setValue(
      `${draftsPaths[type]}/${coachId}/${draftId}`,
      draftData
    );
    res = draftData;
  } catch (error) {
    notifyHandledError(error, { message: 'Unable to delete draft' });
  }
  return res;
}

export { deleteDraft, listDrafts, updateDraft, createDraft };
