const draftsType = {
  COMMUNITIES: 'communities',
  COURSES: 'courses',
};

const draftsPaths = {
  communities: `/drafts/communities`,
  courses: `/drafts/courses`,
};

module.exports = { draftsPaths, draftsType };
