/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentFocus: '',
};

export const previewAction = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    setCurrentFocus: (state, action) => {
      state.currentFocus = action.payload;
    },
  },
});

export const { setCurrentFocus } = previewAction.actions;

export default previewAction.reducer;
