import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  listDrafts,
  deleteDraft,
  createDraft,
  updateDraft,
} from '../../models/drafts';

export const getDraftsList = createAsyncThunk(
  'drafts/getDraftsList',
  async ({ coachId, type }, { rejectWithValue }) => {
    try {
      const draftList = await listDrafts(coachId, type);
      return draftList;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const deleteDrafts = createAsyncThunk(
  'drafts/deleteDrafts',
  async ({ coachId, type, draftId }, { rejectWithValue }) => {
    try {
      const response = await deleteDraft({
        coachId,
        type,
        draftId,
      });
      return response;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const createDrafts = createAsyncThunk(
  'drafts/createDrafts',
  async ({ coachId, type, data }, { rejectWithValue }) => {
    try {
      const response = await createDraft({
        coachId,
        type,
        data,
      });
      if (!response) rejectWithValue();
      return response;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const updateDrafts = createAsyncThunk(
  'drafts/updateDrafts',
  async ({ coachId, type, data, draftId }, { rejectWithValue }) => {
    try {
      const response = await updateDraft({
        coachId,
        type,
        data,
        draftId,
      });
      if (!response) rejectWithValue();
      return response;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

const initialState = {
  courses: {
    list: [],
    isLoading: false,
    isFetched: false,
    selectedDraft: '',
  },
  communities: {
    list: [],
    isLoading: false,
    isFetched: false,
    selectedDraft: '',
  },
};

/* eslint-disable no-param-reassign */
export const draftstSlice = createSlice({
  name: 'drafts',
  initialState,
  reducers: {
    setSelectedDraft: (state, action) => {
      state[action.payload.type].selectedDraft = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDraftsList.pending, (state, action) => {
        state[action.meta.arg.type].isLoading = true;
      })

      .addCase(getDraftsList.fulfilled, (state, action) => {
        state[action.meta.arg.type].isLoading = false;
        state[action.meta.arg.type].list = action.payload || [];
        state[action.meta.arg.type].isFetched = true;
      })

      .addCase(getDraftsList.rejected, (state, action) => {
        state[action.meta.arg.type].isLoading = true;
      })
      .addCase(deleteDrafts.fulfilled, (state, action) => {
        state[action.meta.arg.type].list = state[
          action.meta.arg.type
        ].list.filter((item) => item?.id !== action.meta.arg?.draftId);
        state[action.meta.arg.type].selectedDraft = null;
      })
      .addCase(createDrafts.fulfilled, (state, action) => {
        state[action.meta.arg.type].list = [
          action.payload,
          ...state[action.meta.arg.type].list,
        ];
        state[action.meta.arg.type].selectedDraft = action.payload;
      })
      .addCase(updateDrafts.fulfilled, (state, action) => {
        const index = state[action.meta.arg.type].list.findIndex(
          (item) => item.id === action.meta.arg.draftId
        );
        const newArray = [...state[action.meta.arg.type].list];
        newArray.splice(index, 1, action.payload);
        state[action.meta.arg.type].list = newArray;
        state[action.meta.arg.type].selectedDraft = action.payload;
      });
  },
});
/* eslint-disable no-param-reassign */

export const { setSelectedDraft } = draftstSlice.actions;

export default draftstSlice.reducer;
