import { format, formatDistance } from 'date-fns';
import { en, es, ptBR, fr, de, ja, ko } from 'date-fns/locale';

let currentLocale = 'en';

function init(locale) {
  // Intl API requires locales to use hyphens instead of underscores eg. en-US, pt-BR
  currentLocale = locale?.replace('_', '-');
}

function formatCurrency(
  value = 0,
  { currency = 'USD', maximumFractionDigits = null, locale } = {}
) {
  const options = { style: 'currency', currency };
  if (Number.isInteger(maximumFractionDigits)) {
    options.maximumFractionDigits = maximumFractionDigits;
  }
  return Intl.NumberFormat(locale || currentLocale, options).format(value);
}

function formatPercentage(value) {
  return Intl.NumberFormat(currentLocale, { style: 'percent' }).format(value);
}

function formatNumber(
  value = 0,
  { notation, compactDisplay, maximumFractionDigits } = {}
) {
  const options = { notation, compactDisplay, maximumFractionDigits };
  return Intl.NumberFormat(currentLocale, {
    style: 'decimal',
    ...options,
  }).format(value);
}

function getCurrentDateLocale() {
  switch (currentLocale) {
    case 'en':
      return en;
    case 'es':
      return es;
    case 'fr':
      return fr;
    case 'de':
      return de;
    case 'pt_BR':
      return ptBR;
    case 'kr':
      return ko;
    case 'ja':
      return ja;
    default:
      return en;
  }
}

function formatDate(date, formatString = 'MMM dd') {
  return format(date, formatString, { locale: getCurrentDateLocale() });
}

function formatDateDistance(date, dateToCompare, options) {
  return formatDistance(date, dateToCompare, {
    locale: getCurrentDateLocale(),
    ...options,
  });
}

const I18NFormatter = {
  init,
  formatCurrency,
  formatPercentage,
  formatNumber,
  getCurrentDateLocale,
  formatDate,
  formatDateDistance,
};

export default I18NFormatter;
